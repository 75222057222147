/* You can add global styles to this file, and also import other style files */
@import "@mynexus/mynexus-ui-lib/styles/assets/theme/mynexus/variables/public-api.scss";

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
  vertical-align: text-bottom;
}

.expand-group button {
  &:focus {
    box-shadow: none;
  }

  .pi {
    font-size: 0.875rem;
  }
}

.default-field-size {
  width: 14rem;
}

// check if needed
.p-fluid .p-inputtext {
  width: 100%;
}

.active {
  color: $secondary-color
}

.inactive {
  color: $escalatedCase
}

.full-width {
  width: 100%;
}

.p-column-filter {
  max-width: 16rem;
}

.custom-message.p-inline-message-info {
  height: 28px;
  background-color: $secondaryBackground;
}

.custom-message .p-inline-message-text {
  color: $secondary-color-hover;
  font-weight: 400;
  font-size: 12px;
}

.custom-message .p-inline-message-info .p-inline-message-icon {
  color: $secondary-color-hover;
  width: 15px;
  height: 15px;
}

.p-progress-spinner-sm.p-progress-spinner {
  width: 84px;
  height: 84px;
}

.p-confirm-dialog-message {
  white-space: pre-line;
}